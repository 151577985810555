import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {ROUTE_LOGIN} from '../lib/utils/router';

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(ROUTE_LOGIN);
    }, []);
    return (
        <>
            <h1>Home</h1>
        </>
    );
};
