import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    DefaultOptions,
    useApolloClient,
    useMutation,
    useQuery,
} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {NetworkError} from '@apollo/client/errors';
import {onError} from '@apollo/client/link/error';
import {relayStylePagination} from '@apollo/client/utilities';
import {CacheProvider} from '@emotion/react';
import {PaletteMode, useMediaQuery} from '@mui/material';
import {ThemeOptions, ThemeProvider, createTheme, responsiveFontSizes} from '@mui/material/styles';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {Settings} from 'luxon';
import React, {Suspense, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {I18nextProvider} from 'react-i18next';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Outlet, RouterProvider, createBrowserRouter, useNavigate} from 'react-router-dom';
import {SnackbarGenericComponent} from './components/functional/SnackbarComponent';
import {AppLayout} from './components/structure/AppLayout';
import {Preloader} from './components/structure/Preloader';
import i18n from './i18n';
import {LOCAL_KEY_TOKEN, SESSION_KEY_APP_STATE, SNACKBAR_AUTO_HIDE_DURATION} from './lib/constants';
import {setSnackbar} from './lib/redux/actions/snackbarActions';
import {setTheme} from './lib/redux/actions/themeActions';
import {USER_LOGIN_SUCCESS, USER_LOGOUT, USER_SESSION_EXPIRED} from './lib/redux/actions/userActions';
import {getStore} from './lib/redux/initStore';
import {GM_LOGIN_USER_TOKEN, GQ_USER_WHOAMI} from './lib/services/polypublisher/gql/user';
import {err} from './lib/utils/logger';
import {
    ROUTE_ARTICLES,
    ROUTE_ARTICLES_EDIT,
    ROUTE_ARTICLES_LANG,
    ROUTE_AUDIT,
    ROUTE_CHANNELS,
    ROUTE_CHANNELS_EDIT,
    ROUTE_DASHBOARD,
    ROUTE_EVENTS_EDIT,
    ROUTE_EVENTS_LANG,
    ROUTE_FILES_LANG,
    ROUTE_HOME,
    ROUTE_IMAGES,
    ROUTE_LOGIN,
    ROUTE_LOGOUT,
    ROUTE_MEDIA_FILES_LANG,
    ROUTE_NEWSLETTER,
    ROUTE_PAGES,
    ROUTE_PAGES_EDIT,
    ROUTE_PAGES_LANG,
    ROUTE_PAYMENTS_LANG,
    ROUTE_POINTS_LANG,
    ROUTE_SEARCH_USERS,
    ROUTE_SOCIAL_LINKS,
    ROUTE_TAGS,
    ROUTE_TEAMS,
    ROUTE_TUTORIALS,
    ROUTE_USER,
} from './lib/utils/router';
import {loadLocalData, loadSessionData, removeLocalData, removeSessionData, saveLocalData} from './lib/utils/storage';
import createEmotionCache from './styles/createEmotionCache';
import {getThemeOptions} from './styles/theme';
import {AuthenticationRoleEnum, WhoAmI} from './types/graphqlTypes';
import {AppState, UserState, UserStatus} from './types/redux';

// MUI X PRO license
import {LicenseInfo} from '@mui/x-license-pro';
import {HomePage} from './pages/HomePage';
import {LoginPage} from './pages/LoginPage';
import {LogoutPage} from './pages/LogoutPage';
import {GraphQLFormattedError} from 'graphql/error';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE ?? '');

// set the global timezone and local (Luxon)
Settings.defaultLocale = 'de';
Settings.defaultZone = 'Europe/Berlin';

const store = getStore(loadSessionData(SESSION_KEY_APP_STATE));

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const InternalApp: React.FC<{
    setNewAuthToken(token: string | null): void;
    graphQlErrors: ReadonlyArray<GraphQLFormattedError> | undefined;
    networkError: NetworkError | undefined;
}> = ({setNewAuthToken, graphQlErrors, networkError}) => {
    const user = useSelector<AppState, UserState>((state) => state.user);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [askWhoAmI, setAskWhoAmI] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const {loading, error, data} = useQuery(GQ_USER_WHOAMI, {
        fetchPolicy: 'no-cache',
        skip: !askWhoAmI,
    });

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    // if we have a token in the query params, i.e. masquerade as a user, delete all local data and login as new user
    const queryParams = new URLSearchParams(window.location.search);
    const queryToken = queryParams.get('token') ?? null;
    const [loginToken] = useMutation(GM_LOGIN_USER_TOKEN);
    const client = useApolloClient();

    // check once on loading if we have a querytoken for i.e. magicLink login / masquerading
    useEffect(() => {
        if (queryToken) {
            setIsLoading(true);
            removeLocalData(LOCAL_KEY_TOKEN);
            removeSessionData(SESSION_KEY_APP_STATE);
            setNewAuthToken(null);
            setAskWhoAmI(false);

            client.clearStore().then(() => {
                loginToken({variables: {token: queryToken}})
                    .then((res) => {
                        // save token
                        saveLocalData(LOCAL_KEY_TOKEN, res?.data?.loginByToken);
                        setNewAuthToken(res?.data?.loginByToken);
                        client.resetStore().then(() => {
                            setAskWhoAmI(true);
                        });
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            });
        }
    }, []);

    useEffect(() => {
        if (askWhoAmI && data?.whoAmI) {
            const userStatus: WhoAmI = data?.whoAmI;
            const showAdminOptions =
                userStatus.role === AuthenticationRoleEnum.Root ||
                userStatus.role === AuthenticationRoleEnum.Administrator;
            const showRootOptions = userStatus.role === AuthenticationRoleEnum.Root;
            if (userStatus?.user) {
                if (
                    userStatus.role === AuthenticationRoleEnum.Root ||
                    userStatus.role === AuthenticationRoleEnum.Editor ||
                    userStatus.role === AuthenticationRoleEnum.Administrator
                ) {
                    setIsLoading(false);
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: {
                            authToken: loadLocalData(LOCAL_KEY_TOKEN),
                            userId: userStatus.user,
                            userName: userStatus.fullName,
                            userEmail: userStatus.email,
                            role: userStatus.role,
                            showAdminOptions: showAdminOptions,
                            showRootOptions: showRootOptions,
                            avatar: userStatus.avatar,
                        },
                    });
                } else {
                    dispatch({
                        type: USER_LOGOUT,
                    });
                }
            } else {
                err('App found error on login');
                dispatch({
                    type: USER_SESSION_EXPIRED,
                });
            }
        }
    }, [data]);

    useEffect(() => {
        switch (user.status) {
            case UserStatus.LOGIN_IN_PROGRESS:
                setNewAuthToken(loadLocalData(LOCAL_KEY_TOKEN));
                setAskWhoAmI(true);
                break;
            case UserStatus.LOGGED_OUT:
            case UserStatus.SESSION_EXPIRED:
                setNewAuthToken(null);
                setAskWhoAmI(false);
                navigate(ROUTE_LOGOUT);
                break;
            case UserStatus.ANONYMOUS:
                setNewAuthToken(loadLocalData(LOCAL_KEY_TOKEN));
                setAskWhoAmI(true);
                break;
            case UserStatus.LOGGED_IN:
            case UserStatus.LOGIN_FAILED:
            default:
                setAskWhoAmI(false);
                break;
        }
    }, [user]);

    // show graphql errors for mutations
    useEffect(() => {
        graphQlErrors &&
            graphQlErrors.length &&
            dispatch(
                setSnackbar({
                    isOpen: true,
                    severity: 'error',
                    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
                    message: graphQlErrors
                        .map((graphError) => {
                            if ((graphError as any).debugMessage) {
                                return (graphError as any).debugMessage;
                            }
                            return graphError.message;
                        })
                        .join(', '),
                }),
            );
    }, [graphQlErrors]);

    // show graphql fetch errors for i.e. queries
    useEffect(() => {
        networkError &&
            dispatch(
                setSnackbar({
                    isOpen: true,
                    severity: 'error',
                    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
                    message: networkError.message,
                }),
            );
    }, [networkError]);

    // theme switcher (to user preferred style)
    const riffThemeIsUserPreference = useSelector<AppState>((state) => state.theme.isUserPreference);
    const riffThemeMode = useSelector<AppState>((state) => state.theme.mode);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const riffTheme = React.useMemo(
        () => responsiveFontSizes(createTheme(getThemeOptions(riffThemeMode as PaletteMode) as ThemeOptions)),
        [riffThemeMode],
    );

    // initial setting of theme mode is the user preference
    useEffect(() => {
        if (!riffThemeIsUserPreference && prefersDarkMode) {
            dispatch(
                setTheme({
                    mode: 'dark',
                    isUserPreference: true,
                }),
            );
        }
    }, [prefersDarkMode, riffThemeIsUserPreference]);

    // TODO use data routes with createBrowserRouter:
    // https://reactrouter.com/en/6.8.1/routers/picking-a-router#using-v64-data-apis
    return (
        <ThemeProvider theme={riffTheme}>
            <Helmet>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <meta charSet="utf-8" />
                <title>PolyCMS</title>
            </Helmet>
            <AppLayout>
                {error ? (
                    <h2>{error.message}</h2>
                ) : isLoading ? (
                    <Preloader />
                ) : (
                    <Suspense fallback={<Preloader />}>
                        <Outlet />
                    </Suspense>
                )}
            </AppLayout>
        </ThemeProvider>
    );
};

export const App: React.FC = () => {
    const [authToken, setAuthToken] = useState(loadLocalData(LOCAL_KEY_TOKEN));

    const uploadLink = createUploadLink({
        uri: process.env.REACT_APP_POLYPUBLISHER_GRAPH_URL,
        credentials: 'same-origin',
        headers: {
            Authorization: authToken ?? '',
            PolyPlatform: process.env.NEXT_PUBLIC_POLYPLATFORM ?? 'RIFFREPORTER',
            ClientIP: '',
            ClientUserAgent: '',
        },
    });

    const [graphQlErrors, setGraphQlErrors] = useState<ReadonlyArray<GraphQLFormattedError> | undefined>(undefined);
    const [networkError, setNetworkError] = useState<NetworkError | undefined>(undefined);

    // afterware for handling Apollo errors
    const errorLink = onError(({graphQLErrors, networkError}) => {
        // log the errors
        if (graphQLErrors && graphQLErrors.length > 0) {
            const errorObject: Record<string, any> = {...graphQLErrors[0]};
            delete errorObject['message'];
            err('Apollo error: ' + graphQLErrors[0].message, errorObject);
        }
        if (networkError) {
            err('Network error: ' + networkError.message, networkError);
        }

        // authentication error -> logout the user
        // if needed more often, include error categories and codes in the backend
        if (
            graphQLErrors &&
            graphQLErrors?.filter(
                (e) =>
                    e.message.includes('You do not have authorization') ||
                    e.message.includes('You are not allowed') ||
                    e.message.includes('Token invalid'),
            ).length > 0
        ) {
            if (store) {
                err('App logging out');
                // remove token
                removeLocalData(LOCAL_KEY_TOKEN);
                removeSessionData(SESSION_KEY_APP_STATE);
                store.dispatch<any>({type: USER_SESSION_EXPIRED});
            } else {
                err('App cannot handle onError auth error, no dispatch provided');
            }
        }
        // set all other errors to display them in a snackbar
        setGraphQlErrors(graphQLErrors ? graphQLErrors : []);
        setNetworkError(networkError);
    });

    const defaultOptions: DefaultOptions = {
        // disable caching
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        // mutate: {
        //     fetchPolicy: 'no-cache',
        // },
    };

    const apolloClient = new ApolloClient({
        link: ApolloLink.from([errorLink, uploadLink as unknown as ApolloLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Image: {
                    fields: {
                        src: relayStylePagination(),
                        picture: relayStylePagination(),
                    },
                },
            },
        }),
        defaultOptions: defaultOptions,
    });

    function setNewAuthToken(token: string | null) {
        setAuthToken(token);
    }

    // routes
    const router = createBrowserRouter([
        {
            element: (
                <InternalApp
                    setNewAuthToken={setNewAuthToken}
                    graphQlErrors={graphQlErrors}
                    networkError={networkError}
                />
            ),
            children: [
                {
                    path: ROUTE_LOGIN,
                    element: <LoginPage />,
                },
                {
                    path: ROUTE_LOGOUT,
                    element: <LogoutPage />,
                },
                {
                    path: ROUTE_HOME,
                    element: <HomePage />,
                },
                {
                    path: `${ROUTE_USER}/:locale/:userId`,
                    lazy: async () => {
                        const {UserPage} = await import('./pages/UserPage');
                        return {Component: UserPage};
                    },
                },
                {
                    path: ROUTE_ARTICLES,
                    lazy: async () => {
                        const {ArticlesPage} = await import('./pages/ArticlesPage');
                        return {Component: ArticlesPage};
                    },
                },
                {
                    path: `${ROUTE_ARTICLES_LANG}/:locale`,
                    lazy: async () => {
                        const {ArticlesList} = await import('./pages/Articles/ArticlesList');
                        return {Component: ArticlesList};
                    },
                },
                {
                    path: `${ROUTE_ARTICLES_EDIT}/:locale/:articleId`,
                    lazy: async () => {
                        const {EditArticlePage} = await import('./pages/Articles/EditArticlePage');
                        return {Component: EditArticlePage};
                    },
                },
                {
                    path: ROUTE_PAGES,
                    lazy: async () => {
                        const {PagesPage} = await import('./pages/PagesPage');
                        return {Component: PagesPage};
                    },
                },
                {
                    path: `${ROUTE_PAGES_LANG}/:locale`,
                    lazy: async () => {
                        const {PagesList} = await import('./pages/Pages/PagesList');
                        return {Component: PagesList};
                    },
                },
                {
                    path: `${ROUTE_PAGES_EDIT}/:locale/:pageId`,
                    lazy: async () => {
                        const {EditPagePage} = await import('./pages/Pages/EditPagePage');
                        return {Component: EditPagePage};
                    },
                },
                {
                    path: `${ROUTE_PAYMENTS_LANG}/:locale`,
                    lazy: async () => {
                        const {PaymentsList} = await import('./pages/Payments/PaymentsList');
                        return {Component: PaymentsList};
                    },
                },
                {
                    path: ROUTE_DASHBOARD,
                    lazy: async () => {
                        const {DashboardPage} = await import('./pages/DashboardPage');
                        return {Component: DashboardPage};
                    },
                },
                {
                    path: `${ROUTE_CHANNELS}/:locale`,
                    lazy: async () => {
                        const {ChannelsList} = await import('./pages/Channels/ChannelsList');
                        return {Component: ChannelsList};
                    },
                },
                {
                    path: `${ROUTE_CHANNELS_EDIT}/:locale/:channelId`,
                    lazy: async () => {
                        const {EditChannelPage} = await import('./pages/Channels/EditChannelPage');
                        return {Component: EditChannelPage};
                    },
                },
                {
                    path: `${ROUTE_TEAMS}/:locale`,
                    lazy: async () => {
                        const {TeamsList} = await import('./pages/Teams/TeamsList');
                        return {Component: TeamsList};
                    },
                },
                {
                    path: `${ROUTE_TEAMS}/:locale/:teamId`,
                    lazy: async () => {
                        const {EditTeamPage} = await import('./pages/Teams/EditTeamPage');
                        return {Component: EditTeamPage};
                    },
                },
                {
                    path: ROUTE_NEWSLETTER,
                    lazy: async () => {
                        const {NewsletterPage} = await import('./pages/NewsletterPage');
                        return {Component: NewsletterPage};
                    },
                },
                {
                    path: ROUTE_IMAGES,
                    lazy: async () => {
                        const {ImagesPage} = await import('./pages/ImagesPage');
                        return {Component: ImagesPage};
                    },
                },
                {
                    path: `${ROUTE_MEDIA_FILES_LANG}/:locale`,
                    lazy: async () => {
                        const {MediaFilesList} = await import('./pages/MediaFiles/MediaFilesList');
                        return {Component: MediaFilesList};
                    },
                },
                {
                    path: `${ROUTE_FILES_LANG}/:locale`,
                    lazy: async () => {
                        const {FilesList} = await import('./pages/Files/FilesList');
                        return {Component: FilesList};
                    },
                },
                {
                    path: `${ROUTE_POINTS_LANG}/:locale`,
                    lazy: async () => {
                        const {PointsPage} = await import('./pages/Points/PointsPage');
                        return {Component: PointsPage};
                    },
                },
                {
                    path: ROUTE_TUTORIALS,
                    lazy: async () => {
                        const {TutorialsPage} = await import('./pages/TutorialsPage');
                        return {Component: TutorialsPage};
                    },
                },
                {
                    path: ROUTE_AUDIT,
                    lazy: async () => {
                        const {AuditList} = await import('./pages/Audit/AuditList');
                        return {Component: AuditList};
                    },
                },
                {
                    path: ROUTE_SEARCH_USERS,
                    lazy: async () => {
                        const {SearchUsersPage} = await import('./pages/SearchUsersPage');
                        return {Component: SearchUsersPage};
                    },
                },
                {
                    path: `${ROUTE_TAGS}/:locale`,
                    lazy: async () => {
                        const {TagsPage} = await import('./pages/TagsPage');
                        return {Component: TagsPage};
                    },
                },
                {
                    path: ROUTE_SOCIAL_LINKS,
                    lazy: async () => {
                        const {SocialLinksPage} = await import('./pages/SocialLinksPage');
                        return {Component: SocialLinksPage};
                    },
                },
                {
                    path: `${ROUTE_EVENTS_LANG}/:locale`,
                    lazy: async () => {
                        const {EventsList} = await import('./pages/Events/EventsList');
                        return {Component: EventsList};
                    },
                },
                {
                    path: `${ROUTE_EVENTS_EDIT}/:locale/:eventId`,
                    lazy: async () => {
                        const {EditEventPage} = await import('./pages/Events/EditEventPage');
                        return {Component: EditEventPage};
                    },
                },
            ],
        },
    ]);

    return (
        <CacheProvider value={clientSideEmotionCache}>
            <Provider store={store}>
                <ApolloProvider client={apolloClient}>
                    <I18nextProvider i18n={i18n}>
                        <RouterProvider router={router} />
                        <SnackbarGenericComponent />
                    </I18nextProvider>
                </ApolloProvider>
            </Provider>
        </CacheProvider>
    );
};
