import {EditPaymentDataState} from '../../../types/redux';

export const EDIT_PAYMENT_DATA_SET_PAYMENT = 'EDIT_PAYMENT_DATA_SET_PAYMENT';
export const EDIT_PAYMENT_DATA_RESET_PAYMENT = 'EDIT_PAYMENT_DATA_RESET_PAYMENT';

export const setPaymentData = (editPaymentDataState: EditPaymentDataState) => {
    return {
        type: EDIT_PAYMENT_DATA_SET_PAYMENT,
        payload: editPaymentDataState,
    };
};

export const resetPaymentData = () => {
    return {
        type: EDIT_PAYMENT_DATA_RESET_PAYMENT,
    };
};
