import {EditImageMetaDataAction, EditImageMetaDataState} from '../../../types/redux';
import {EDIT_IMAGE_META_DATA_RESET_IMAGE, EDIT_IMAGE_META_DATA_SET_IMAGE} from '../actions/editImageMetaDataActions';

const initialState: EditImageMetaDataState = {
    isOpen: false,
    image: null,
    imageIndex: null,
    // paragraph: null,
    paragraphType: null,
    paragraphIndex: null,
    refetchQuery: null,
};

export const editImageMetaDataReducer = (
    state: EditImageMetaDataState = initialState,
    action: EditImageMetaDataAction,
): EditImageMetaDataState => {
    switch (action.type) {
        case EDIT_IMAGE_META_DATA_SET_IMAGE:
            return {...action.payload};
        case EDIT_IMAGE_META_DATA_RESET_IMAGE:
            return initialState;
        default:
            return state;
    }
};
