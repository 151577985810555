import {ThemeState} from '../../../types/redux';

export const SET_THEME = 'SET_THEME';

export const setTheme = (themeState: ThemeState) => {
    return {
        type: SET_THEME,
        payload: themeState,
    };
};
