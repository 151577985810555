import {SnackbarAction, SnackbarState} from '../../../types/redux';
import {RESET_SNACKBAR, SET_SNACKBAR} from '../actions/snackbarActions';
import {SNACKBAR_AUTO_HIDE_DURATION} from '../../constants';

const initialState: SnackbarState = {
    isOpen: false,
    severity: 'error',
    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
    message: null,
    errors: null,
};

export const snackbarReducer = (state: SnackbarState = initialState, action: SnackbarAction): SnackbarState => {
    switch (action.type) {
        case SET_SNACKBAR:
            return {...action.payload};
        case RESET_SNACKBAR:
            return {
                ...initialState,
                severity: state.severity,
            };
        default:
            return state;
    }
};
