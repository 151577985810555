import * as Sentry from '@sentry/react';
import {alpha, darken} from '@mui/material';
import {colors} from '../../styles/theme';

export const initializeSentry = () => {
    const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED ?? false;
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN ?? '';
    const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? '';
    if (sentryEnabled && sentryDsn !== '') {
        Sentry.init({
            dsn: sentryDsn,
            environment: sentryEnvironment,
            release: process.env.REACT_APP_RELEASE,
            integrations: [
                Sentry.browserTracingIntegration({
                    enableInp: true,
                }),
                Sentry.replayIntegration({
                    networkDetailAllowUrls: ['https://api.polypublisher.com/graph'],
                    networkRequestHeaders: ['X-Custom-Header'],
                    networkResponseHeaders: ['X-Custom-Header'],
                    maskAllText: false,
                    maskAllInputs: false,
                    blockAllMedia: false,
                }),
                Sentry.replayCanvasIntegration(),
                Sentry.httpClientIntegration(),
                Sentry.captureConsoleIntegration({
                    levels: ['warn', 'error'],
                }),
                Sentry.feedbackIntegration({
                    colorScheme: 'light',
                    showBranding: false,
                    autoInject: false,
                    showEmail: true,
                    showName: true,
                    isNameRequired: true,
                    isEmailRequired: true,
                    useSentryUser: {
                        name: 'fullName',
                        email: 'email',
                    },
                    formTitle: 'Bug-Report',
                    nameLabel: 'Name',
                    namePlaceholder: 'Bitte deinen Namen angeben',
                    emailLabel: 'E-Mail',
                    emailPlaceholder: 'Bitte deine E-Mail-Adresse angeben',
                    messageLabel: 'Beschreibung',
                    messagePlaceholder: 'Was ist passiert? Was hättest du erwartet?',
                    submitButtonLabel: 'Bug melden',
                    cancelButtonLabel: 'Abbrechen',
                    successMessageText: 'Vielen Dank für deinen Bug-Report!',
                    themeLight: {
                        submitBackground: colors.turquoise50,
                        submitBackgroundHover: darken(colors.turquoise50, 0.2),
                        submitBorder: alpha(colors.turquoise50, 0.25),
                        submitOutlineFocus: alpha(colors.turquoise50, 0.25),
                        inputOutlineFocus: alpha(colors.turquoise50, 0.75),
                    },
                }),
            ],
            debug: false,
            sendDefaultPii: true,
            tracesSampleRate: 0.1, // Trace performance for 10% of transactions
            replaysSessionSampleRate: 0.0, // Disable session replay collection entirely
            replaysOnErrorSampleRate: 1.0, // Enable session replay for all errors
        });
    }
};
