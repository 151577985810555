import {UploadFileState} from '../../../types/redux';

export const UPLOAD_FILE_SET = 'UPLOAD_FILE_SET';
export const UPLOAD_FILE_RESET = 'UPLOAD_IMAGE_RESET';

export const setFileUpload = (uploadFileState: UploadFileState) => {
    return {
        type: UPLOAD_FILE_SET,
        payload: uploadFileState,
    };
};

export const resetFileUpload = () => {
    return {
        type: UPLOAD_FILE_RESET,
    };
};
