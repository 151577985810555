import {ThemeAction, ThemeState} from '../../../types/redux';
import {SET_THEME} from '../actions/themeActions';

const initialState: ThemeState = {
    mode: 'light',
    isUserPreference: false,
};

export const themeReducer = (state: ThemeState = initialState, action: ThemeAction): ThemeState => {
    switch (action.type) {
        case SET_THEME:
            return {...action.payload};
        default:
            return state;
    }
};
