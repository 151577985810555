import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {colors} from '../../styles/theme';

interface PreloaderProps {
    fullHeight?: boolean;
    minWidth?: number | string;
    minHeight?: number | string;
    isWhite?: boolean;
}

export const Preloader: React.FC<PreloaderProps> = ({
    minWidth = 50,
    minHeight = 50,
    fullHeight = false,
    isWhite = false,
}) => {
    const mH = fullHeight ? `calc(100vh)` : minHeight;

    return (
        <Box
            sx={{
                display: 'flex',
                minWidth: minWidth,
                minHeight: mH,
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 0,
                color: isWhite ? colors.white : colors.turquoise50,
            }}
        >
            <CircularProgress color={'inherit'} />
        </Box>
    );
};
