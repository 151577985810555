export const ROUTE_ARTICLES = '/articles';
export const ROUTE_ARTICLES_LANG = '/articles';
export const ROUTE_ARTICLES_EDIT = '/articles';
export const ROUTE_PAGES = '/pages';
export const ROUTE_PAGES_LANG = '/pages';
export const ROUTE_PAGES_EDIT = '/pages';
export const ROUTE_PAYMENTS = '/payments';
export const ROUTE_PAYMENTS_LANG = '/payments';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_IMAGES = '/images';
export const ROUTE_MEDIA_FILES_LANG = '/mediaFiles';
export const ROUTE_FILES_LANG = '/files';
export const ROUTE_POINTS_LANG = '/points';
export const ROUTE_CHANNELS = '/magazines';
export const ROUTE_CHANNELS_EDIT = '/magazines';
export const ROUTE_TEAMS = '/teams';
export const ROUTE_NEWSLETTER = '/newsletter';
export const ROUTE_USER = '/user';
export const ROUTE_TAGS = '/tags';
export const ROUTE_TUTORIALS = '/tutorials';
export const ROUTE_AUDIT = '/audit';
export const ROUTE_SEARCH_USERS = '/searchUsers';
export const ROUTE_SOCIAL_LINKS = '/socialLinks';
export const ROUTE_EVENTS_LANG = '/events';
export const ROUTE_EVENTS_EDIT = '/events';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_HOME = '/';

/**
 * Each route can be mapped to a route type and each route type related to a specific Page component to render the content
 */
// export enum RouteTypeEnum {
//     AUTHOR_OVERVIEW = 'AUTHOR_OVERVIEW',
//     AUTHOR = 'AUTHOR',
//     CHANNEL_OVERVIEW = 'CHANNEL_OVERVIEW',
//     CHANNEL = 'CHANNEL',
//     TAG_OVERVIEW = 'TAG_OVERVIEW',
//     TAG = 'TAG',
//     RESSORT = 'RESSORT',
//     ARTICLE = 'ARTICLE',
//     PAGE = 'PAGE',
//     HOME = 'HOME',
//     LOGIN = 'LOGIN',
//     LOGOUT = 'LOGOUT',
//     REGISTER_USER = 'REGISTER_USER',
//     IMPRINT = 'IMPRINT',
//     PRIVACY = 'PRIVACY',
//     WITHDRAWAL = 'WITHDRAWAL',
//     TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
//     ERROR = 'ERROR',
//     FAQ = 'FAQ',
//     ABOUT = 'ABOUT',
//     USER_PROFILE = 'USER_PROFILE',
//     USER_PURCHASES = 'USER_PURCHASES',
//     DISCLAIMER = 'DISCLAIMER',
//     SUPPORT = 'SUPPORT',
//     FOR_PUBLISHER = 'FOR_PUBLISHER',
//     FOR_PROMOTER = 'FOR_PROMOTER',
//     HOW_TO_RIFFREPORTER = 'HOW_TO_RIFFREPORTER',
//     RESET_PASSWORD = 'RESET_PASSWORD',
//     HOW_TO_PAY = 'HOW_TO_PAY',
//     JOURNALISTIC_CODEX = 'JOURNALISTIC_CODEX',
//     TEAM = 'TEAM',
//     COOPERATIVE = 'COOPERATIVE',
// }

export interface ParamTypes {
    [key: string]: string;
    locale: string;
    userId: string;
    articleId: string;
    pageId: string;
    channelId: string;
    teamId: string;
    eventId: string;
}

// export const getLangEnum = (locale: string): LanguageEnum => {
//     return locale === 'de' ? LanguageEnum.DE : LanguageEnum.EN;
// };
