import {combineReducers, Reducer} from 'redux';
import {userReducer} from './userReducer';
import {AppState} from '../../../types/redux';
import {editImageMetaDataReducer} from './editImageMetaDataReducer';
import {uploadImageReducer} from './uploadImageReducer';
import {snackbarReducer} from './snackbarReducer';
import {uploadFileReducer} from './uploadFileReducer';
import {editFileMetaDataReducer} from './editFileMetaDataReducer';
import {themeReducer} from './themeReducer';
import {editPaymentDataReducer} from './editPaymentDataReducer';

export const rootReducer = (): Reducer<AppState> =>
    combineReducers({
        // router: connectRouter(history),
        user: userReducer,
        editImageMetaData: editImageMetaDataReducer,
        editFileMetaData: editFileMetaDataReducer,
        editPaymentData: editPaymentDataReducer,
        uploadImage: uploadImageReducer,
        uploadFile: uploadFileReducer,
        snackbar: snackbarReducer,
        theme: themeReducer,
    });
