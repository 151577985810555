import {createStore, Store} from 'redux';
import {loadSessionData, saveSessionData} from '../utils/storage';
import {rootReducer} from './reducers';
import {SESSION_KEY_APP_STATE} from '../constants';
import {AppState} from '../../types/redux';

// export const browserHistory = createBrowserHistory();
// improve scroll behaviour when navigating via https://oaf-project.github.io/oaf-react-router/
// wrapHistory(browserHistory);

let store: Store | null = null;

export function initStore(preloadedState?: AppState): Store {
    // const composeEnhancer: typeof compose =
    //     typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
    //         ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25})
    //         : compose;
    const store = createStore(
        rootReducer(),
        preloadedState as any,
        // composeEnhancer(applyMiddleware(routerMiddleware(browserHistory), thunk)),
    );

    store.subscribe(() => {
        saveSessionData(SESSION_KEY_APP_STATE, store.getState());
    });

    // Hot reloading

    if ((module as any).hot) {
        // Enable Webpack hot module replacement for reducers
        // (module as any).hot.accept('./reducers', () => {
        //     store.replaceReducer(rootReducer(browserHistory));
        // });
    }

    return store;
}

export const getStore = (preloadedState?: AppState): Store => {
    if (!store) {
        store = initStore(preloadedState);
    }

    return store;
};

let clientStore: Store | null | undefined = null;

export const initializeStore = (preloadedState?: AppState) => {
    // return client store if available
    if (clientStore) {
        return clientStore;
    }

    // get persisted state from browser storage
    const clientState = loadSessionData(SESSION_KEY_APP_STATE) ?? {};

    const _store = initStore({
        ...(preloadedState ?? []), // state from SSR
        ...(clientState ?? []), // persisted client state
    });

    // Create the store once in the client
    clientStore = _store;

    _store.subscribe(() => {
        saveSessionData(SESSION_KEY_APP_STATE, _store?.getState());
    });

    return _store;
};
