import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {AppNavigation} from './navigation/AppNavigation';
import {AppFooter} from './AppFooter';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';

export const AppLayout: React.FC<{
    children?: React.ReactNode;
}> = ({children}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <CssBaseline />
            <AppNavigation />
            <Box
                component={'main'}
                sx={{
                    flexGrow: 1,
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    maxWidth: `calc(100vw - 56px)`,
                    [theme.breakpoints.up('md')]: {
                        padding: 3,
                        maxWidth: `calc(100vw - 86px)`,
                    },
                }}
            >
                {/* page content */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Box>
                {/* modal portal root */}
                <Box id={'modal-root'} className={'modal-root'}></Box>
                {/* app footer */}
                <AppFooter />
            </Box>
        </Box>
    );
};
