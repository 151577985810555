import {UploadImageAction, UploadImageState} from '../../../types/redux';
import {UPLOAD_IMAGE_RESET_IMAGE, UPLOAD_IMAGE_SET_IMAGE} from '../actions/uploadImageActions';

const initialState: UploadImageState = {
    isOpen: false,
};

export const uploadImageReducer = (
    state: UploadImageState = initialState,
    action: UploadImageAction,
): UploadImageState => {
    switch (action.type) {
        case UPLOAD_IMAGE_SET_IMAGE:
            return {...action.payload};
        case UPLOAD_IMAGE_RESET_IMAGE:
            return initialState;
        default:
            return state;
    }
};
