import {Location} from 'history';
import {AuthenticationRoleEnum, File, Image, Media, ParagraphTypeEnum, Payment} from './graphqlTypes';
import {LanguageEnum} from '../lib/constants';
import {DocumentNode} from 'graphql';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {AlertColor} from '@mui/material';

// export type DispatchThunkAny = ThunkDispatch<Promise<any>, void, Action<string>>;

export interface AppState {
    user: UserState;
    editImageMetaData: EditImageMetaDataState;
    editFileMetaData: EditFileMetaDataState;
    editPaymentData: EditPaymentDataState;
    uploadImage: UploadImageState;
    uploadFile: UploadFileState;
    snackbar: SnackbarState;
    theme: ThemeState;
}

export interface AppStore {
    dispatch(action: any): any;
    getState(): AppState;
}

export enum UserStatus {
    ANONYMOUS = 'ANONYMOUS',
    LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
}

export interface UserState {
    authToken: string | null;
    status: UserStatus;
    errorMessage: string | null;
    userId: string | null;
    userName: string | null;
    userEmail: string | null;
    role: AuthenticationRoleEnum | null;
    redirectLocation: Location | null;
    language: LanguageEnum | null;
    fullAccess: boolean;
    hasAcceptedThirdPartyContent: boolean;
    accountHide: boolean | null;
    accountMessage: string | null;
    showAdminOptions: boolean;
    showRootOptions: boolean;
    avatar: string | null;
}

export interface EditImageMetaDataState {
    isOpen: boolean;
    image: Image | null | undefined;
    imageIndex: number | null | undefined;
    paragraphType: ParagraphTypeEnum | null | undefined;
    paragraphIndex: number | null | undefined;
    refetchQuery: DocumentNode | null;
}

export interface EditImageMetaDataAction {
    type: string;
    payload: {
        isOpen: boolean;
        image: Image | null | undefined;
        imageIndex: number | null | undefined;
        paragraphType: ParagraphTypeEnum | null | undefined;
        paragraphIndex: number | null | undefined;
        refetchQuery: DocumentNode | null;
    };
}

export interface EditFileMetaDataState {
    isOpen: boolean;
    file: File | Media | null | undefined;
    fileIndex: number | null | undefined;
    isMediaFile: boolean;
    paragraphType: ParagraphTypeEnum | null | undefined;
    paragraphIndex: number | null | undefined;
    refetchQuery: DocumentNode | null;
}

export interface EditFileMetaDataAction {
    type: string;
    payload: {
        isOpen: boolean;
        file: File | null | undefined;
        fileIndex: number | null | undefined;
        isMediaFile: boolean;
        paragraphType: ParagraphTypeEnum | null | undefined;
        paragraphIndex: number | null | undefined;
        refetchQuery: DocumentNode | null;
    };
}

export interface EditPaymentDataState {
    isOpen: boolean;
    payment: Payment | null | undefined;
    paymentIndex: number | null | undefined;
    refetchQuery: DocumentNode | null;
}

export interface EditPaymentDataAction {
    type: string;
    payload: {
        isOpen: boolean;
        payment: Payment | null | undefined;
        paymentIndex: number | null | undefined;
        refetchQuery: DocumentNode | null;
    };
}

export interface UploadImageState {
    isOpen: boolean;
}

export interface UploadImageAction {
    type: string;
    payload: {
        isOpen: boolean;
    };
}

export interface UploadFileState {
    isOpen: boolean;
    isMediaFile: boolean;
}

export interface UploadFileAction {
    type: string;
    payload: {
        isOpen: boolean;
        isMediaFile: boolean;
    };
}

export interface SnackbarState {
    isOpen: boolean;
    severity: AlertColor;
    autoHideDuration: number | null;
    message?: string | null;
    errors?: FieldErrors | null;
}

export interface SnackbarAction {
    type: string;
    payload: {
        isOpen: boolean;
        severity: AlertColor;
        autoHideDuration: number | null;
        message?: string | null;
        errors?: FieldErrors | null;
    };
}

export interface ThemeState {
    mode?: string | null;
    isUserPreference: boolean;
}

export interface ThemeAction {
    type: string;
    payload: {
        mode: string;
        isUserPreference: boolean;
    };
}
