import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import translationDE from './locales/de/translation.json';
import {err, log} from './lib/utils/logger';

// null values as valid translation
declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'de',
        resources: {
            de: {
                translation: translationDE,
            },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            lookupQuerystring: 'lang',
        },
    })
    .then(() => log('i18n initialized successfully'))
    .catch((error) => err('An error occurred while initializing i18n', error));

export default i18n;
