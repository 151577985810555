import {
    USER_LOGIN_ATTEMPT,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_SESSION_EXPIRED,
} from '../actions/userActions';
import {UserState, UserStatus} from '../../../types/redux';
import {Location} from 'history';
import {AuthenticationRoleEnum} from '../../../types/graphqlTypes';

export const initialUserState: UserState = {
    authToken: null,
    status: UserStatus.ANONYMOUS,
    errorMessage: null,
    userId: null,
    userName: null,
    userEmail: null,
    role: AuthenticationRoleEnum.Anonymous,
    redirectLocation: null,
    language: null,
    fullAccess: false,
    hasAcceptedThirdPartyContent: false,
    accountHide: null,
    accountMessage: null,
    showAdminOptions: false,
    showRootOptions: false,
    avatar: null,
};

type UserActionProps =
    | {
          type: typeof USER_LOGIN_ATTEMPT;
          payload?: Location;
      }
    | {
          type: typeof USER_LOGIN_ERROR;
          payload: string;
      }
    | {
          type: typeof USER_LOGIN_SUCCESS;
          payload: {authToken: string; userId: string};
      }
    | {
          type: typeof USER_LOGOUT;
      }
    | {
          type: typeof USER_SESSION_EXPIRED;
      };

export const userReducer = (state: UserState = initialUserState, action: UserActionProps): UserState => {
    switch (action.type) {
        case USER_LOGIN_ATTEMPT:
            return {
                ...state,
                redirectLocation: action.payload ?? null,
                errorMessage: null,
                status: UserStatus.LOGIN_IN_PROGRESS,
            };
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                errorMessage: null,
                ...action.payload,
                status: UserStatus.LOGGED_IN,
            };
        case USER_LOGIN_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                status: UserStatus.LOGIN_FAILED,
            };
        case USER_LOGOUT:
            return {
                ...initialUserState,
                status: UserStatus.LOGGED_OUT,
            };
        case USER_SESSION_EXPIRED:
            return {
                ...initialUserState,
                status: UserStatus.SESSION_EXPIRED,
            };
        default:
            return state;
    }
};
