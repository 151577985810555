import {EditImageMetaDataState} from '../../../types/redux';

export const EDIT_IMAGE_META_DATA_SET_IMAGE = 'EDIT_IMAGE_META_DATA_SET_IMAGE';
export const EDIT_IMAGE_META_DATA_RESET_IMAGE = 'EDIT_IMAGE_META_DATA_RESET_IMAGE';

export const setImageMetaData = (editImageMetaDataState: EditImageMetaDataState) => {
    return {
        type: EDIT_IMAGE_META_DATA_SET_IMAGE,
        payload: editImageMetaDataState,
    };
};

export const resetImageMetaData = () => {
    return {
        type: EDIT_IMAGE_META_DATA_RESET_IMAGE,
    };
};
