import React, {memo, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Box, Grid, Typography} from '@mui/material';
import {ROUTE_HOME, ROUTE_LOGIN} from '../lib/utils/router';
import {useTranslation} from 'react-i18next';
import {Link, NavLink} from 'react-router-dom';
import {AppState, UserState, UserStatus} from '../types/redux';
import {useSelector} from 'react-redux';
import {LogoRIcon} from '../assets/UiIcons';
import {useApolloClient, useMutation} from '@apollo/client';
import {err, log} from '../lib/utils/logger';
import {GM_LOGOUT_USER} from '../lib/services/polypublisher/gql/user';
import {removeLocalData, removeSessionData} from '../lib/utils/storage';
import {LOCAL_KEY_TOKEN, SESSION_KEY_APP_STATE} from '../lib/constants';

export const LogoutPage: React.FC = () => {
    const user: UserState = useSelector<AppState, UserState>((state) => state.user);
    const {t} = useTranslation();
    const client = useApolloClient();

    const MemoizedLogoRIcon = memo(LogoRIcon);

    const title =
        user.status === UserStatus.SESSION_EXPIRED ? t('logout.sessionExpired.title') : t('logout.manual.title');
    const subtitle =
        user.status === UserStatus.SESSION_EXPIRED ? t('logout.sessionExpired.subtitle') : t('logout.manual.subtitle');

    const [isLoggingOut, setIsLoggingOut] = useState(true);
    const [logoutUser] = useMutation(GM_LOGOUT_USER);
    useEffect(() => {
        const logout = async () => {
            try {
                // remove token
                removeLocalData(LOCAL_KEY_TOKEN);
                removeSessionData(SESSION_KEY_APP_STATE);
                // send backend mutation
                await logoutUser({});
                // clear apollo store
                await client.clearStore();
                log('Logout succeeded');
            } catch (error) {
                err('Logout error', error);
            } finally {
                setIsLoggingOut(false);
            }
        };

        logout().catch((err) => {
            err('Logout error', err);
        });
    }, [client, logoutUser]);

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: 'calc(100vh)',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid container justifyContent="center">
                <Grid item xs={10} sm={8} lg={6}>
                    <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                        <NavLink to={ROUTE_HOME}>
                            <MemoizedLogoRIcon color={'primary'} sx={{mb: 6}} />
                        </NavLink>
                    </Box>
                    {isLoggingOut ? (
                        <Typography variant={'h2'} textAlign={'center'}>
                            {t('logout.process')}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant={'h2'} my={3} textAlign={'center'}>
                                {title}
                            </Typography>
                            <Typography variant={'h3'} textAlign={'center'}>
                                {subtitle}
                            </Typography>
                            <Grid item xs={12} mt={3} sx={{mt: 6}}>
                                <Button
                                    component={Link}
                                    to={ROUTE_LOGIN}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                >
                                    {t('logout.login')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
