import {SnackbarState} from '../../../types/redux';

export const SET_SNACKBAR = 'SET_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';

export const setSnackbar = (snackbarState: SnackbarState) => {
    return {
        type: SET_SNACKBAR,
        payload: snackbarState,
    };
};

export const resetSnackbar = () => {
    return {
        type: RESET_SNACKBAR,
    };
};
