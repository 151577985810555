import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';

const hideOnMobile = true;
const hideOnDesktop = true;

export const AppFooter: React.FC = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: 40,
                marginTop: theme.spacing(3),
                padding: theme.spacing(3),
                justifyContent: 'center',
                alignItems: 'center',
                display: hideOnMobile ? 'none' : 'flex',
                [theme.breakpoints.up('md')]: {
                    display: hideOnDesktop ? 'none' : 'flex',
                },
            }}
        >
            <Typography variant={'caption'}>RiffReporter CMS</Typography>
        </Box>
    );
};
