import gql from 'graphql-tag';
import {FRAGMENT_IMAGE, FRAGMENT_IMAGE_SRC_ALIASES} from './fragmentsImage';
import {FRAGMENT_USER_MAIN} from './fragmentsUser';

export const GM_LOGIN_USER = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password)
    }
`;

export const GM_LOGIN_USER_TOKEN = gql`
    mutation loginByToken($token: String!) {
        loginByToken(token: $token)
    }
`;

export const GM_LOGOUT_USER = gql`
    mutation logout {
        logout
    }
`;

export const GQ_USER_WHOAMI = gql`
    query whoAmI {
        whoAmI {
            user
            role
            state
            fullName
            email
            avatar
            authHeader
            authCookie
        }
    }
`;

export const GQ_RETRIEVE_USER = gql`
    query retrieveUser($id: ID!, $locale: LocaleEnum, $ignoreCache: Boolean) {
        user(id: $id, locale: $locale, ignoreCache: $ignoreCache) {
            ...FragmentUserMain
            slug
            hero {
                ...FragmentImage
                ...FragmentImageSrcAliases
                inUse
            }
            portrait {
                ...FragmentImage
                ...FragmentImageSrcAliases
                inUse
            }
            emails(only_main: false, only_public: false) {
                id
                email
                isMain
                isPublic
            }
            contacts(only_main: false, only_public: false) {
                id
                value
                isMain
                isPublic
            }
            vgwort {
                number
                firstname
                lastname
                excluded
                rightsTransferred
                reproductionRight
                distributionRight
                publicAccessRight
                otherRightsOfPublicReproduction
                withOwnParticipation
            }
            detail {
                jobTitle
                description
                descriptionShort
                transparency
                publisher
                team
                events
                ustid
                taxNumber
                taxCountry
                smallBusiness
                foreignBusiness
                freischreiber
                birthday
            }
            demographics {
                birthYear
                education
                employment
            }
            financial {
                accountHolder
                iban
                bic
            }
            links {
                id
                url
                type
                subtype
            }
            addresses(only_public: false) {
                id
                type
                line1
                line2
                line3
                city
                postcode
                country
                countryName
                isPublic
            }
            speaker {
                id
                active
                description
                awards
                performances
            }
        }
    }
    ${FRAGMENT_USER_MAIN}
    ${FRAGMENT_IMAGE}
    ${FRAGMENT_IMAGE_SRC_ALIASES}
`;

export const GM_UPDATE_USER_PROFILE = gql`
    mutation updateUser($userId: ID!, $input: UpdateUserInput!) {
        user {
            update(userId: $userId, input: $input) {
                id
            }
        }
    }
`;

// /profile - get countries for dropdown
export const GQ_RETRIEVE_COUNTRIES = gql`
    query countries {
        helper {
            countries {
                id
                alpha2
                name
                __typename
            }
            __typename
        }
    }
`;
