import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {App} from './App';
import {initializeSentry} from './lib/services/SentryTracking';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// sentry error & performance tracking
initializeSentry();

root.render(<App />);
