import {EditPaymentDataAction, EditPaymentDataState} from '../../../types/redux';
import {EDIT_PAYMENT_DATA_RESET_PAYMENT, EDIT_PAYMENT_DATA_SET_PAYMENT} from '../actions/editPaymentDataActions';

const initialState: EditPaymentDataState = {
    isOpen: false,
    payment: null,
    paymentIndex: null,
    refetchQuery: null,
};

export const editPaymentDataReducer = (
    state: EditPaymentDataState = initialState,
    action: EditPaymentDataAction,
): EditPaymentDataState => {
    switch (action.type) {
        case EDIT_PAYMENT_DATA_SET_PAYMENT:
            return {...action.payload};
        case EDIT_PAYMENT_DATA_RESET_PAYMENT:
            return initialState;
        default:
            return state;
    }
};
