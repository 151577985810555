import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React, {ReactElement} from 'react';

export function LogoRIcon(props: SvgIconProps): ReactElement<SvgIconProps> {
    return (
        <SvgIcon width="19px" height="24px" viewBox="0 0 19 24" {...props}>
            <path d="M14,10 L14,4 L9.15085324,4 C7.05392491,4.2192846 6,5.04510109 6,6.47278383 L6,7.30793157 L6.25665529,8.12908243 C6.25665529,9.3748056 8.43549488,10 12.7877133,10 L14,10 L14,10 Z M19,0 L19,24 L13.3474872,24 L13.3474872,14.5741935 L9.48723461,14.5741935 L9.27277614,14.7822581 C7.38860521,20.433871 6.17844665,23.5064516 5.65251277,24 L0,24 L4.05939264,13.5 C1.50120935,11.9129032 0.219564633,9.90967742 0.219564633,7.48548387 L0.219564633,7.27741935 C0.219564633,5.46774194 0.668906208,3.96774194 1.57269551,2.77258065 C2.73689868,0.924193548 4.8559527,0 7.91964526,0 L19,0 L19,0 Z" />
        </SvgIcon>
    );
}

export function CloseIcon(props: SvgIconProps): ReactElement<SvgIconProps> {
    return (
        <SvgIcon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <path d="M12.1216288,10.0003125 L18.5318291,3.59011219 L19.8537454,2.26819588 C20.0487515,2.07318979 20.0487515,1.75630488 19.8537454,1.56129879 L18.4393262,0.14687959 C18.2443201,-0.048126504 17.9274352,-0.048126504 17.7324291,0.14687959 L10.0003125,7.87899622 L2.26819588,0.14625457 C2.07318979,-0.0487515235 1.75630488,-0.0487515235 1.56129879,0.14625457 L0.14625457,1.56067377 C-0.0487515235,1.75567986 -0.0487515235,2.07256477 0.14625457,2.26757086 L7.87899622,10.0003125 L0.14625457,17.7324291 C-0.0487515235,17.9274352 -0.0487515235,18.2443201 0.14625457,18.4393262 L1.56067377,19.8537454 C1.75567986,20.0487515 2.07256477,20.0487515 2.26757086,19.8537454 L10.0003125,12.1216288 L16.4105128,18.5318291 L17.7324291,19.8537454 C17.9274352,20.0487515 18.2443201,20.0487515 18.4393262,19.8537454 L19.8537454,18.4393262 C20.0487515,18.2443201 20.0487515,17.9274352 19.8537454,17.7324291 L12.1216288,10.0003125 Z" />
        </SvgIcon>
    );
}

export function RtlArrowIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={18} viewBox={'0 0 24 18'} {...props}>
            <path d="M13.8047 16.8984L7.09688 9.77344C6.85312 9.55782 6.75 9.27657 6.75 9C6.75 8.72344 6.85256 8.44313 7.05759 8.22657L13.7654 1.1016C14.1932 0.6504 14.905 0.6314 15.3559 1.0576C15.81 1.4854 15.826 2.2004 15.3998 2.6486L9.38107 9.00012L15.3998 15.3517C15.8261 15.7999 15.81 16.5118 15.3559 16.9426C14.9485 17.3686 14.2359 17.3498 13.8047 16.8984Z" />
        </SvgIcon>
    );
}

export function LtrArrowIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={18} viewBox={'0 0 24 18'} {...props}>
            <path d="M10.1953 1.10156L16.9031 8.22656C17.1469 8.44218 17.25 8.72343 17.25 9C17.25 9.27656 17.1474 9.55687 16.9424 9.77343L10.2346 16.8984C9.8068 17.3496 9.095 17.3686 8.6441 16.9424C8.19 16.5146 8.174 15.7996 8.6002 15.3514L14.6189 8.99988L8.6002 2.64832C8.1739 2.2001 8.19 1.48816 8.6441 1.05738C9.0515 0.631406 9.7641 0.650156 10.1953 1.10156Z" />
        </SvgIcon>
    );
}

export function InfoIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={24} viewBox={'0 0 24 24'} {...props}>
            <path d="M11 9H13V7H11M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM11 17H13V11H11V17Z" />
        </SvgIcon>
    );
}
