import {EditFileMetaDataState} from '../../../types/redux';

export const EDIT_FILE_META_DATA_SET_FILE = 'EDIT_FILE_META_DATA_SET_FILE';
export const EDIT_FILE_META_DATA_RESET_FILE = 'EDIT_FILE_META_DATA_RESET_FILE';

export const setFileMetaData = (editFileMetaDataState: EditFileMetaDataState) => {
    return {
        type: EDIT_FILE_META_DATA_SET_FILE,
        payload: editFileMetaDataState,
    };
};

export const resetFileMetaData = () => {
    return {
        type: EDIT_FILE_META_DATA_RESET_FILE,
    };
};
