import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import {Box, Grid, TextField, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {AppState, UserState, UserStatus} from '../types/redux';
import {NavLink} from 'react-router-dom';
import {LogoRIcon} from '../assets/UiIcons';
import {useMutation} from '@apollo/client';
import {GM_LOGIN_USER} from '../lib/services/polypublisher/gql/user';
import {Preloader} from '../components/structure/Preloader';
import {Controller, useForm} from 'react-hook-form';
import {ROUTE_ARTICLES_LANG, ROUTE_HOME} from '../lib/utils/router';
import {saveLocalData} from '../lib/utils/storage';
import {LOCAL_KEY_TOKEN} from '../lib/constants';
import {USER_LOGIN_ATTEMPT} from '../lib/redux/actions/userActions';

interface LoginValues {
    username: string;
    password: string;
}

export const LoginPage: React.FC = () => {
    const user: UserState = useSelector<AppState, UserState>((state) => state.user);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const MemoizedLogoRIcon = memo(LogoRIcon);

    const [loginUser] = useMutation(GM_LOGIN_USER);
    const [isLoading, setIsLoading] = useState(false);

    // form submit handler
    const onSubmit = (values: LoginValues) => {
        setIsLoading(true);
        loginUser({
            variables: {username: values.username, password: values.password},
        })
            .then((res) => {
                setIsLoading(false);
                // save token
                saveLocalData(LOCAL_KEY_TOKEN, res?.data?.login);
                dispatch({
                    type: USER_LOGIN_ATTEMPT,
                });
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    // redirect to home after successful login
    useEffect(() => {
        if (user.status === UserStatus.LOGGED_IN) {
            navigate(user.redirectLocation ?? {pathname: `${ROUTE_ARTICLES_LANG}/de`});
        }
    }, [user]);

    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data as LoginValues))}>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: 'calc(100vh)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent="center">
                    <Grid item xs={10} sm={8} lg={6}>
                        <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                            <NavLink to={ROUTE_HOME}>
                                <MemoizedLogoRIcon color={'primary'} />
                            </NavLink>
                        </Box>
                        <Typography variant={'h2'} my={3} textAlign={'center'}>
                            {t('login.title')}
                        </Typography>
                        <Typography variant={'h3'} textAlign={'center'}>
                            {t('login.subtitle')}
                        </Typography>
                        <Grid item xs={12} mt={6}>
                            <Controller
                                name={'username'}
                                control={control}
                                defaultValue={''}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        value={field.value ?? ''}
                                        label={t('login.username')}
                                        type={'text'}
                                        autoComplete={'username'}
                                        fullWidth={true}
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.username && errors.username.type === 'required' && (
                                <span>{t('validation.shouldNotBeEmpty', {item: t('login.username')})}</span>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={'password'}
                                control={control}
                                defaultValue={''}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        value={field.value ?? ''}
                                        label={t('login.password')}
                                        type={'password'}
                                        autoComplete={'current-password'}
                                        fullWidth={true}
                                        sx={{mt: 3}}
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.password && errors.password.type === 'required' && (
                                <span>{t('validation.shouldNotBeEmpty', {item: t('login.password')})}</span>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            {isLoading ? (
                                <Preloader />
                            ) : (
                                <Button type="submit" variant="contained" color="primary" fullWidth={true}>
                                    {t('login.login')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
