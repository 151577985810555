import {UploadFileAction, UploadFileState} from '../../../types/redux';
import {UPLOAD_FILE_RESET, UPLOAD_FILE_SET} from '../actions/uploadFileActions';

const initialState: UploadFileState = {
    isOpen: false,
    isMediaFile: false,
};

export const uploadFileReducer = (state: UploadFileState = initialState, action: UploadFileAction): UploadFileState => {
    switch (action.type) {
        case UPLOAD_FILE_SET:
            return {...action.payload};
        case UPLOAD_FILE_RESET:
            return initialState;
        default:
            return state;
    }
};
