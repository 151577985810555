import {UploadImageState} from '../../../types/redux';

export const UPLOAD_IMAGE_SET_IMAGE = 'UPLOAD_IMAGE_SET_IMAGE';
export const UPLOAD_IMAGE_RESET_IMAGE = 'UPLOAD_IMAGE_RESET_IMAGE';

export const setImageUpload = (uploadImageState: UploadImageState) => {
    return {
        type: UPLOAD_IMAGE_SET_IMAGE,
        payload: uploadImageState,
    };
};

export const resetImageUpload = () => {
    return {
        type: UPLOAD_IMAGE_RESET_IMAGE,
    };
};
