import {gql} from '@apollo/client';
import {FRAGMENT_IMAGE_SRC} from './fragmentsImage';

export const FRAGMENT_USER_MAIN = gql`
    fragment FragmentUserMain on User {
        id
        gender
        title
        firstName
        middleName
        lastName
        fullName
        nickName
        avatar {
            ...FragmentImageSrc
        }
    }
    ${FRAGMENT_IMAGE_SRC}
`;

export const FRAGMENT_USER_CONTRIBUTOR = gql`
    fragment FragmentUserContributor on Contributor {
        user {
            ...FragmentUserMain
        }
        function
        custom
    }
    ${FRAGMENT_USER_MAIN}
`;
