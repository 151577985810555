import {EditFileMetaDataAction, EditFileMetaDataState} from '../../../types/redux';
import {EDIT_FILE_META_DATA_RESET_FILE, EDIT_FILE_META_DATA_SET_FILE} from '../actions/editFileMetaDataActions';

const initialState: EditFileMetaDataState = {
    isOpen: false,
    file: null,
    fileIndex: null,
    isMediaFile: false,
    paragraphType: null,
    paragraphIndex: null,
    refetchQuery: null,
};

export const editFileMetaDataReducer = (
    state: EditFileMetaDataState = initialState,
    action: EditFileMetaDataAction,
): EditFileMetaDataState => {
    switch (action.type) {
        case EDIT_FILE_META_DATA_SET_FILE:
            return {...action.payload};
        case EDIT_FILE_META_DATA_RESET_FILE:
            return initialState;
        default:
            return state;
    }
};
